import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

const Home = Loadable(lazy(() => import('views/Home')));
const Clients = Loadable(lazy(() => import('views/Clients')));
const SuccessAtZrg = Loadable(lazy(() => import('views/SuccessAtZRG')));
const History = Loadable(lazy(() => import('views/History')));
const Employees = Loadable(lazy(() => import('views/Employees')));
const Construction = Loadable(lazy(() => import('views/Construction')));
const Logout = Loadable(lazy(() => import('components/core/Logout')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Home />,
      default: true
    },
    {
      path: 'home',
      element: <Home />
    },
    {
      path: 'success-at-zrg',
      element: <SuccessAtZrg />
    },
    {
      path: 'success-at-zrg/history',
      element: <History />
    },
    {
      path: 'success-at-zrg/employees',
      element: <Employees />
    },
    {
      path: 'success-at-zrg/clients',
      element: <Clients />
    },
    {
      path: 'construction',
      element: <Construction />
    },
    {
      path: 'sign-out',
      element: <Logout />
    }
  ]
};

export default MainRoutes;
