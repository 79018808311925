import MainRoutes from './MainRoutes';
import SuccessRoutes from './SuccessRoutes';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [MainRoutes, MainRoutes, SuccessRoutes]
};

export default menuItems;
