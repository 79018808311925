// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth'; // Import getAuth for Firebase Authentication
import { getFirestore } from 'firebase/firestore'; // Import getFirestore for Firebase Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDLZiwvMJBe1JCtcqweAno3TkJTrrTwXqU',
  authDomain: 'zrg-internal-tools.firebaseapp.com',
  projectId: 'zrg-internal-tools',
  storageBucket: 'zrg-internal-tools.appspot.com',
  messagingSenderId: '1316981032',
  appId: '1:1316981032:web:92762a8d31f72166efe81d'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app); // Initialize Firebase Analytics if you're using it

// Initialize Firebase Authentication and Firestore
export const auth = getAuth(app); // Get the Auth service for the default app
export const firestore = getFirestore(app); // Get a Firestore instance for the default app
