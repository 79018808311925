// material-ui
import { Box, Typography } from '@mui/material';
// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useLocation } from 'react-router-dom';
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  let menuIndex = 1;
  const location = useLocation();
  const containsSuccessAtZRG = location.pathname.includes('/success-at-zrg');
  if (containsSuccessAtZRG) {
    menuIndex = 2;
  }

  const menu = { items: [menuItem.items[menuIndex]] };
  console.log(menu);
  const navGroups = menu.items.map((item) => {
    console.log('i', item);
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
