// assets
import {
  DashboardOutlined,
  HomeOutlined,
  HistoryOutlined,
  LogoutOutlined,
  FundProjectionScreenOutlined,
  ProfileOutlined
} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  HomeOutlined,
  HistoryOutlined,
  LogoutOutlined,
  ProfileOutlined,
  FundProjectionScreenOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const MainRoutes = {
  id: 'group-dashboard',
  title: 'Admin',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      url: '/home',
      icon: icons.HomeOutlined,
      breadcrumbs: false
    },
    {
      id: 'success-at-zrg',
      title: 'Success At ZRG',
      type: 'item',
      url: '/success-at-zrg',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'decks',
      title: 'Decks',
      type: 'item',
      url: '/construction',
      icon: icons.FundProjectionScreenOutlined,
      breadcrumbs: false
    },
    {
      id: 'proposals',
      title: 'Proposals',
      type: 'item',
      url: '/construction',
      icon: icons.ProfileOutlined,
      breadcrumbs: false
    },
    {
      id: 'sign-out',
      title: 'Sign Out',
      type: 'item',
      url: '/sign-out',
      icon: icons.LogoutOutlined,
      breadcrumbs: false
    }
  ]
};

export default MainRoutes;
