// assets
import { DashboardOutlined, HomeOutlined, HistoryOutlined, TeamOutlined, RiseOutlined, LogoutOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  HomeOutlined,
  HistoryOutlined,
  TeamOutlined,
  RiseOutlined,
  LogoutOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const SuccessRoutes = {
  id: 'group-dashboard',
  title: 'Admin',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      url: '/home',
      icon: icons.HomeOutlined,
      breadcrumbs: false
    },
    {
      id: 'success-at-zrg',
      title: 'Success At ZRG',
      type: 'item',
      url: '/success-at-zrg',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'history',
      title: 'History',
      type: 'item',
      url: '/success-at-zrg/history',
      icon: icons.HistoryOutlined,
      breadcrumbs: false
    },
    {
      id: 'employees',
      title: 'Employees',
      type: 'item',
      url: '/success-at-zrg/employees',
      icon: icons.TeamOutlined,
      breadcrumbs: false
    },
    {
      id: 'clients',
      title: 'Clients',
      type: 'item',
      url: '/success-at-zrg/clients',
      icon: icons.RiseOutlined,
      breadcrumbs: false
    },
    {
      id: 'sign-out',
      title: 'Sign Out',
      type: 'item',
      url: '/sign-out',
      icon: icons.LogoutOutlined,
      breadcrumbs: false
    }
  ]
};

export default SuccessRoutes;
