// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import userReducer from './userType';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, userType: userReducer });

export default reducers;
